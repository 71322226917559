package me.nielps.da.yafte

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.CanvasBasedWindow
import me.nielps.da.yafte.di.ApplicationComponent
import me.nielps.da.yafte.di.create
import org.jetbrains.skiko.wasm.onWasmReady

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    val applicationComponent = ApplicationComponent.create()

    onWasmReady {
        CanvasBasedWindow(title = "daniel@web") {
            Column(
                modifier = Modifier.fillMaxSize(),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            ) {
                WindowDecoration(
                    modifier = Modifier
                        .padding(16.dp)
                        .sizeIn(maxWidth = 800.dp, maxHeight = 500.dp)
                ) {
                    App(applicationComponent)
                }

                Text(
                    "Written by mrdanielps\nPowered by KMP & Jetpack Compose",
                    modifier = Modifier.padding(8.dp),
                    style = MaterialTheme.typography.bodySmall,
                    textAlign = TextAlign.Center,
                )
            }
        }
    }
}

@Composable
fun WindowDecoration(
    modifier: Modifier = Modifier,
    content: @Composable () -> Unit
) {
    Column(modifier) {
        Surface(
            shadowElevation = 8.dp,
            tonalElevation = 8.dp,
            color = MaterialTheme.colorScheme.surface,
            shape = MaterialTheme.shapes.small,
        ) {
            Column {
                Box(
                    modifier = Modifier.fillMaxWidth(),
                    contentAlignment = Alignment.CenterEnd,
                ) {
                    Text("Terminal", Modifier.align(Alignment.Center))
                    WindowControls(Modifier.align(Alignment.CenterEnd))
                }
                Box(
                    modifier = Modifier.weight(1f)
                ) {
                    content()
                }
            }
        }
    }
}

@Composable
private fun WindowControls(
    modifier: Modifier = Modifier,
) {
    Row(
        modifier = modifier
            .height(32.dp)
            .padding(8.dp),
        horizontalArrangement = Arrangement.spacedBy(4.dp),
    ) {
        val colors = listOf(
            MaterialTheme.colorScheme.outline,
            MaterialTheme.colorScheme.outline,
            MaterialTheme.colorScheme.error,
        )
        repeat(3) {
            Box(
                modifier = Modifier
                    .clip(CircleShape)
                    .background(colors[it])
                    .fillMaxHeight()
                    .aspectRatio(1f)
            )
        }
    }
}