package me.nielps.da.yafte.di

import kotlin.reflect.KClass
import me.nielps.da.yafte.`data`.command.ChangeDirectoryCommandRunner
import me.nielps.da.yafte.`data`.command.CommandRepository
import me.nielps.da.yafte.`data`.command.HelpCommandRunner
import me.nielps.da.yafte.`data`.command.ListFilesCommandRunner
import me.nielps.da.yafte.`data`.command.PrintFileCommandRunner
import me.nielps.da.yafte.`data`.fs.FileSystemDataSource
import me.nielps.da.yafte.domain.CompleteCommandUseCase
import me.nielps.da.yafte.domain.GetWorkingDirectoryUseCase
import me.nielps.da.yafte.domain.ParseCommandUseCase
import me.nielps.da.yafte.domain.RunCommandUseCase
import me.nielps.da.yafte.terminal.TerminalViewModel
import me.tatarka.inject.`internal`.LazyMap
import me.tatarka.inject.`internal`.ScopedComponent

public fun KClass<ApplicationComponent>.create(): ApplicationComponent =
    InjectApplicationComponent()

public class InjectApplicationComponent() : ApplicationComponent(), ScopedComponent {
  override val _scoped: LazyMap = LazyMap()

  private val commandRepository: CommandRepository
    get() = CommandRepository(
      commands = mapOf(
        provideHelpCommand(
          runner = HelpCommandRunner()
        ),
        provideListFilesCommand(
          runner = ListFilesCommandRunner(
            fileSystemDataSource = fileSystemDataSource
          )
        ),
        providePrintFileCommand(
          runner = PrintFileCommandRunner(
            fileSystemDataSource = fileSystemDataSource
          )
        ),
        provideChangeDirectoryCommand(
          runner = ChangeDirectoryCommandRunner(
            fileSystemDataSource = fileSystemDataSource
          )
        )
      )
    )

  private val fileSystemDataSource: FileSystemDataSource
    get() = _scoped.get("me.nielps.da.yafte.`data`.fs.FileSystemDataSource") {
      FileSystemDataSource()
    }

  override val terminalViewModel: TerminalViewModel
    get() = TerminalViewModel(
      runCommandUseCase = RunCommandUseCase(
        parseCommandUseCase = ParseCommandUseCase(),
        commandRepository = commandRepository
      ),
      completeCommandUseCase = CompleteCommandUseCase(
        commandRepository = commandRepository
      ),
      getWorkingDirectoryUseCase = GetWorkingDirectoryUseCase(
        fileSystemDataSource = fileSystemDataSource
      )
    )
}
