package me.nielps.da.yafte.domain

import me.nielps.da.yafte.data.command.CommandRepository
import me.nielps.da.yafte.data.command.CommandResult
import me.tatarka.inject.annotations.Inject

@Inject
class RunCommandUseCase(
    private val parseCommandUseCase: ParseCommandUseCase,
    private val commandRepository: CommandRepository,
) {
    suspend operator fun invoke(command: String): String? {
        if (command.isBlank())
            return null

        val parts = parseCommandUseCase(command)
        val output = commandRepository.run(
            command = parts[0],
            args = parts.drop(1)
        )
        return when (output) {
            is CommandResult.Success -> output.output
            is CommandResult.Error -> output.message
        }
    }
}