package me.nielps.da.yafte

import androidx.compose.runtime.Composable
import me.nielps.da.yafte.di.ApplicationComponent
import me.nielps.da.yafte.terminal.ui.Terminal
import me.nielps.da.yafte.ui.theme.TerminalTheme
import org.jetbrains.compose.ui.tooling.preview.Preview

@Composable
@Preview
fun App(
    applicationComponent: ApplicationComponent,
) {
    TerminalTheme {
        Terminal(
            terminalViewModel = applicationComponent.terminalViewModel,
        )
    }
}