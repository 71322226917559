package me.nielps.da.yafte.data.command

import me.nielps.da.yafte.data.fs.FileSystemDataSource
import me.tatarka.inject.annotations.Inject

@Inject
class ChangeDirectoryCommandRunner(
    private val fileSystemDataSource: FileSystemDataSource,
) : CommandRunner {
    override suspend fun run(args: List<String>): String? {
        val path = args.firstOrNull() ?: "~"
        fileSystemDataSource.changeDirectory(path)
            ?: throw CommandException("cd: $path: No such file or directory")

        return null
    }
}