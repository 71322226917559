package me.nielps.da.yafte.data.command

import me.nielps.da.yafte.data.config.NetworkDirectoryNode
import me.nielps.da.yafte.data.fs.FileSystemDataSource
import me.tatarka.inject.annotations.Inject

@Inject
class ListFilesCommandRunner(
    private val fileSystemDataSource: FileSystemDataSource,
) : CommandRunner {
    override suspend fun run(args: List<String>): String {
        val path = args.firstOrNull() ?: ""
        val files = fileSystemDataSource.listFiles(path)
            ?: throw CommandException("ls: cannot access '$path': No such file or directory")
        return files.joinToString("\n") { "${it.name}${if (it is NetworkDirectoryNode) "/" else ""}" }
    }
}