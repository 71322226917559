package me.nielps.da.yafte.ui.theme

import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.graphics.Color

@Immutable
data class TerminalColorScheme(
    val color01: Color = Color.Unspecified, // Black (Host)
    val color02: Color = Color.Unspecified, // Red (Syntax string)
    val color03: Color = Color.Unspecified, // Green (Command)
    val color04: Color = Color.Unspecified, // Yellow (Command second)
    val color05: Color = Color.Unspecified, // Blue (Path)
    val color06: Color = Color.Unspecified, // Magenta (Syntax var)
    val color07: Color = Color.Unspecified, // Cyan (Prompt)
    val color08: Color = Color.Unspecified, // White
    val color09: Color = Color.Unspecified, // Bright Black
    val color10: Color = Color.Unspecified, // Bright Red (Command error)
    val color11: Color = Color.Unspecified, // Bright Green (Exec)
    val color12: Color = Color.Unspecified, // Bright Yellow
    val color13: Color = Color.Unspecified, // Bright Blue (Folder)
    val color14: Color = Color.Unspecified, // Bright Magenta
    val color15: Color = Color.Unspecified, // Bright Cyan
    val color16: Color = Color.Unspecified, // Bright White
    val background: Color = Color.Unspecified, // Background
    val foreground: Color = Color.Unspecified, // Foreground (Text)
    val cursor: Color = Color.Unspecified, // Cursor
)

val LocalTerminalColorScheme = staticCompositionLocalOf { TerminalColorScheme() }

val MaterialTheme.terminalColorScheme: TerminalColorScheme
    @Composable
    @ReadOnlyComposable
    get() = LocalTerminalColorScheme.current