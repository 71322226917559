package me.nielps.da.yafte.terminal.ui

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.text.selection.SelectionContainer
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.unit.dp
import androidx.lifecycle.compose.collectAsStateWithLifecycle
import me.nielps.da.yafte.terminal.TerminalViewModel
import me.nielps.da.yafte.ui.theme.terminalColorScheme

@Composable
fun Terminal(
    modifier: Modifier = Modifier,
    terminalViewModel: TerminalViewModel,
) {
    val state by terminalViewModel.uiState.collectAsStateWithLifecycle()

    Terminal(
        modifier = modifier,
        cmd = state.input,
        prompt = state.prompt,
        history = state.history,
        onValueChange = terminalViewModel::onValueChange,
        onSend = terminalViewModel::onSend,
        onAutoComplete = terminalViewModel::onAutoComplete,
    )
}

@Composable
private fun Terminal(
    cmd: TextFieldValue,
    prompt: String,
    history: List<String>,
    onValueChange: (TextFieldValue) -> Unit,
    onSend: (String) -> Unit,
    onAutoComplete: (String) -> Unit,
    modifier: Modifier = Modifier,
) {
    val listState = rememberLazyListState()

    LaunchedEffect(history) {
        // Scroll to end with new output
        if (history.isNotEmpty())
            listState.scrollToItem(history.lastIndex)
    }

    Surface(
        modifier = modifier.fillMaxSize(),
        color = MaterialTheme.terminalColorScheme.background,
        contentColor = MaterialTheme.terminalColorScheme.foreground,
    ) {
        SelectionContainer {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp),
                verticalArrangement = Arrangement.Bottom,
            ) {
                LazyColumn(
                    modifier = Modifier.weight(1f),
                    verticalArrangement = Arrangement.Bottom,
                    state = listState,
                ) {
                    items(history) {
                        Text(it, style = MaterialTheme.typography.bodyMedium)
                    }
                }
                TerminalInput(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(bottom = 8.dp),
                    value = cmd,
                    onValueChange = onValueChange,
                    singleLine = true,
                    textStyle = MaterialTheme.typography.bodyMedium,
                    prefix = {
                        Text(prompt)
                    },
                    onSend = onSend,
                    onAutoComplete = onAutoComplete,
                )
            }
        }
    }
}
