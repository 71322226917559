@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package yafte.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.FontResource

private object CommonMainFont0 {
  public val JetBrainsMono_Bold: FontResource by 
      lazy { init_JetBrainsMono_Bold() }

  public val JetBrainsMono_BoldItalic: FontResource by 
      lazy { init_JetBrainsMono_BoldItalic() }

  public val JetBrainsMono_ExtraBold: FontResource by 
      lazy { init_JetBrainsMono_ExtraBold() }

  public val JetBrainsMono_ExtraBoldItalic: FontResource by 
      lazy { init_JetBrainsMono_ExtraBoldItalic() }

  public val JetBrainsMono_ExtraLight: FontResource by 
      lazy { init_JetBrainsMono_ExtraLight() }

  public val JetBrainsMono_ExtraLightItalic: FontResource by 
      lazy { init_JetBrainsMono_ExtraLightItalic() }

  public val JetBrainsMono_Italic: FontResource by 
      lazy { init_JetBrainsMono_Italic() }

  public val JetBrainsMono_Light: FontResource by 
      lazy { init_JetBrainsMono_Light() }

  public val JetBrainsMono_LightItalic: FontResource by 
      lazy { init_JetBrainsMono_LightItalic() }

  public val JetBrainsMono_Medium: FontResource by 
      lazy { init_JetBrainsMono_Medium() }

  public val JetBrainsMono_MediumItalic: FontResource by 
      lazy { init_JetBrainsMono_MediumItalic() }

  public val JetBrainsMono_Regular: FontResource by 
      lazy { init_JetBrainsMono_Regular() }

  public val JetBrainsMono_SemiBold: FontResource by 
      lazy { init_JetBrainsMono_SemiBold() }

  public val JetBrainsMono_SemiBoldItalic: FontResource by 
      lazy { init_JetBrainsMono_SemiBoldItalic() }

  public val JetBrainsMono_Thin: FontResource by 
      lazy { init_JetBrainsMono_Thin() }

  public val JetBrainsMono_ThinItalic: FontResource by 
      lazy { init_JetBrainsMono_ThinItalic() }
}

internal val Res.font.JetBrainsMono_Bold: FontResource
  get() = CommonMainFont0.JetBrainsMono_Bold

private fun init_JetBrainsMono_Bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_Bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-Bold.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_BoldItalic: FontResource
  get() = CommonMainFont0.JetBrainsMono_BoldItalic

private fun init_JetBrainsMono_BoldItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_BoldItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-BoldItalic.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_ExtraBold: FontResource
  get() = CommonMainFont0.JetBrainsMono_ExtraBold

private fun init_JetBrainsMono_ExtraBold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_ExtraBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-ExtraBold.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_ExtraBoldItalic: FontResource
  get() = CommonMainFont0.JetBrainsMono_ExtraBoldItalic

private fun init_JetBrainsMono_ExtraBoldItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_ExtraBoldItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-ExtraBoldItalic.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_ExtraLight: FontResource
  get() = CommonMainFont0.JetBrainsMono_ExtraLight

private fun init_JetBrainsMono_ExtraLight(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_ExtraLight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-ExtraLight.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_ExtraLightItalic: FontResource
  get() = CommonMainFont0.JetBrainsMono_ExtraLightItalic

private fun init_JetBrainsMono_ExtraLightItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_ExtraLightItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-ExtraLightItalic.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_Italic: FontResource
  get() = CommonMainFont0.JetBrainsMono_Italic

private fun init_JetBrainsMono_Italic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_Italic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-Italic.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_Light: FontResource
  get() = CommonMainFont0.JetBrainsMono_Light

private fun init_JetBrainsMono_Light(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_Light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-Light.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_LightItalic: FontResource
  get() = CommonMainFont0.JetBrainsMono_LightItalic

private fun init_JetBrainsMono_LightItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_LightItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-LightItalic.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_Medium: FontResource
  get() = CommonMainFont0.JetBrainsMono_Medium

private fun init_JetBrainsMono_Medium(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_Medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-Medium.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_MediumItalic: FontResource
  get() = CommonMainFont0.JetBrainsMono_MediumItalic

private fun init_JetBrainsMono_MediumItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_MediumItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-MediumItalic.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_Regular: FontResource
  get() = CommonMainFont0.JetBrainsMono_Regular

private fun init_JetBrainsMono_Regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-Regular.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_SemiBold: FontResource
  get() = CommonMainFont0.JetBrainsMono_SemiBold

private fun init_JetBrainsMono_SemiBold(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_SemiBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-SemiBold.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_SemiBoldItalic: FontResource
  get() = CommonMainFont0.JetBrainsMono_SemiBoldItalic

private fun init_JetBrainsMono_SemiBoldItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_SemiBoldItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-SemiBoldItalic.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_Thin: FontResource
  get() = CommonMainFont0.JetBrainsMono_Thin

private fun init_JetBrainsMono_Thin(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_Thin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-Thin.ttf", -1, -1),
    )
)

internal val Res.font.JetBrainsMono_ThinItalic: FontResource
  get() = CommonMainFont0.JetBrainsMono_ThinItalic

private fun init_JetBrainsMono_ThinItalic(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:JetBrainsMono_ThinItalic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/yafte.composeapp.generated.resources/font/JetBrainsMono-ThinItalic.ttf", -1, -1),
    )
)
