package me.nielps.da.yafte.data.command.di

import me.nielps.da.yafte.data.command.ChangeDirectoryCommandRunner
import me.nielps.da.yafte.data.command.CommandRunner
import me.nielps.da.yafte.data.command.HelpCommandRunner
import me.nielps.da.yafte.data.command.ListFilesCommandRunner
import me.nielps.da.yafte.data.command.PrintFileCommandRunner
import me.tatarka.inject.annotations.IntoMap
import me.tatarka.inject.annotations.Provides

interface CommandComponent {
    @IntoMap
    @Provides
    fun provideHelpCommand(runner: HelpCommandRunner): Pair<String, CommandRunner> = "help" to runner

    @IntoMap
    @Provides
    fun provideListFilesCommand(runner: ListFilesCommandRunner): Pair<String, CommandRunner> = "ls" to runner

    @IntoMap
    @Provides
    fun providePrintFileCommand(runner: PrintFileCommandRunner): Pair<String, CommandRunner> = "cat" to runner

    @IntoMap
    @Provides
    fun provideChangeDirectoryCommand(runner: ChangeDirectoryCommandRunner): Pair<String, CommandRunner> = "cd" to runner
}