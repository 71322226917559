package me.nielps.da.yafte.data.fs

import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.async
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asSharedFlow
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.updateAndGet
import kotlinx.serialization.json.Json
import me.nielps.da.yafte.data.config.NetworkConfig
import me.nielps.da.yafte.data.config.NetworkDirectoryNode
import me.nielps.da.yafte.data.config.NetworkFileSystemNode
import me.nielps.da.yafte.di.ApplicationScope
import me.tatarka.inject.annotations.Inject
import org.jetbrains.compose.resources.ExperimentalResourceApi
import yafte.composeapp.generated.resources.Res

@ApplicationScope
@Inject
class FileSystemDataSource {
    private val _cwd = MutableStateFlow("~")
    val cwd = _cwd.asSharedFlow()

    @OptIn(ExperimentalResourceApi::class, DelicateCoroutinesApi::class)
    private val _config = GlobalScope.async {
        Json.decodeFromString<NetworkConfig>(
            Res.readBytes("files/config.json").decodeToString()
        )
    }

    private suspend fun getConfig() = _config.await()

    @OptIn(ExperimentalResourceApi::class)
    suspend fun read(path: String): String {
        if (path.isBlank()) error("Invalid path")

        val absolutePath = if (path.isAbsolute()) path else "${cwd.first()}/$path"
        val nodes = absolutePath.toNodes()
        val resPath = nodes.joinToString("/")
        return Res.readBytes("files/fs/$resPath").decodeToString()
    }

    suspend fun listFiles(path: String): List<NetworkFileSystemNode>? {
        val config = getConfig()
        val absolutePath = if (path.isAbsolute()) path else "${cwd.first()}/$path"
        val nodes = absolutePath.toNodes()

        var curDir = config.fs
        nodes.forEach { node ->
            val match = curDir.firstOrNull { it.name == node }
            if (match is NetworkDirectoryNode)
                curDir = match.files
            else
                return null
        }
        return curDir
    }

    suspend fun changeDirectory(path: String): String? {
        val config = getConfig()
        if (path.isBlank())
            return _cwd.updateAndGet { "~" }

        val absolutePath = if (path.isAbsolute()) path else "${cwd.first()}/$path"
        val nodes = absolutePath.toNodes()
        var curDir = config.fs
        nodes.forEach { node ->
            val match = curDir.firstOrNull { it.name == node }
            if (match is NetworkDirectoryNode)
                curDir = match.files
            else
                return null
        }
        return _cwd.updateAndGet {
            nodes.toPath()
        }
    }

    private fun String.isAbsolute() = this.startsWith("/") || this.startsWith("~")

    private fun String.toNodes(): List<String> {
        val nodes = ArrayDeque<String>()
        this
            .split("/")
            .filter { it.isNotBlank() && it != "~" }
            .forEach { node ->
                when (node) {
                    "." -> Unit
                    ".." -> nodes.removeLast()
                    else -> nodes.addLast(node)
                }
            }
        return nodes.toList()
    }

    private fun List<String>.toPath(): String =
        "~/${this.joinToString("/")}".removeSuffix("/")
}