package me.nielps.da.yafte.data.command

import me.nielps.da.yafte.data.fs.FileSystemDataSource
import me.nielps.da.yafte.model.File
import me.tatarka.inject.annotations.Inject

@Inject
class PrintFileCommandRunner(
    private val fileSystemDataSource: FileSystemDataSource,
) : CommandRunner {
    override suspend fun run(args: List<String>): String? {
        val path = args.firstOrNull() ?: return null
        return runCatching {
            fileSystemDataSource.read(path)
        }.getOrElse { "cat: ${args.first()}: No such file or directory" }
    }
}