package me.nielps.da.yafte.data.config

import kotlinx.serialization.SerialName
import me.nielps.da.yafte.model.Config
import me.nielps.da.yafte.model.FileNode
import kotlinx.serialization.Serializable

@Serializable
data class NetworkConfig(
    val prompt: String,
    val fs: List<NetworkFileSystemNode>
)

@Serializable
sealed interface NetworkFileSystemNode {
    val name: String
}

@Serializable
@SerialName("file")
data class NetworkFileNode(
    override val name: String,
) : NetworkFileSystemNode

@Serializable
@SerialName("dir")
data class NetworkDirectoryNode(
    override val name: String,
    val files: List<NetworkFileSystemNode>
): NetworkFileSystemNode

fun NetworkConfig.asExternalModel() =
    Config(
        prompt = prompt,
        fs = fs.map(NetworkFileSystemNode::asExternalModel)
    )

fun NetworkFileSystemNode.asExternalModel() =
    when (this) {
        is NetworkFileNode -> FileNode(name = name)
        is NetworkDirectoryNode -> {
            error("")
//            DirectoryNode(
//                name = name,
//                files = files.map { it.asExternalModel() }
//            )
        }
    }
