package me.nielps.da.yafte.domain

import kotlinx.coroutines.flow.Flow
import me.nielps.da.yafte.data.fs.FileSystemDataSource
import me.tatarka.inject.annotations.Inject

@Inject
class GetWorkingDirectoryUseCase(
    private val  fileSystemDataSource: FileSystemDataSource,
) {
    operator fun invoke(): Flow<String> = fileSystemDataSource.cwd
}