package me.nielps.da.yafte.ui.theme

import androidx.compose.ui.graphics.Color

val primaryLight = Color(0xFF313446)
val onPrimaryLight = Color(0xFFFFFFFF)
val primaryContainerLight = Color(0xFF53566A)
val onPrimaryContainerLight = Color(0xFFFFFCFF)
val secondaryLight = Color(0xFF006E2B)
val onSecondaryLight = Color(0xFFFFFFFF)
val secondaryContainerLight = Color(0xFF62FF84)
val onSecondaryContainerLight = Color(0xFF00541F)
val tertiaryLight = Color(0xFF87520D)
val onTertiaryLight = Color(0xFFFFFFFF)
val tertiaryContainerLight = Color(0xFFFFC283)
val onTertiaryContainerLight = Color(0xFF583200)
val errorLight = Color(0xFFB71F29)
val onErrorLight = Color(0xFFFFFFFF)
val errorContainerLight = Color(0xFFFF6F6B)
val onErrorContainerLight = Color(0xFF290002)
val backgroundLight = Color(0xFFFCF8FA)
val onBackgroundLight = Color(0xFF1C1B1D)
val surfaceLight = Color(0xFFFCF8FA)
val onSurfaceLight = Color(0xFF1C1B1D)
val surfaceVariantLight = Color(0xFFE3E1E9)
val onSurfaceVariantLight = Color(0xFF46464C)
val outlineLight = Color(0xFF77767D)
val outlineVariantLight = Color(0xFFC7C5CD)
val scrimLight = Color(0xFF000000)
val inverseSurfaceLight = Color(0xFF313032)
val inverseOnSurfaceLight = Color(0xFFF3F0F1)
val inversePrimaryLight = Color(0xFFC3C5DC)
val surfaceDimLight = Color(0xFFDCD9DB)
val surfaceBrightLight = Color(0xFFFCF8FA)
val surfaceContainerLowestLight = Color(0xFFFFFFFF)
val surfaceContainerLowLight = Color(0xFFF6F3F4)
val surfaceContainerLight = Color(0xFFF0EDEE)
val surfaceContainerHighLight = Color(0xFFEBE7E9)
val surfaceContainerHighestLight = Color(0xFFE5E1E3)

val primaryLightMediumContrast = Color(0xFF313446)
val onPrimaryLightMediumContrast = Color(0xFFFFFFFF)
val primaryContainerLightMediumContrast = Color(0xFF53566A)
val onPrimaryContainerLightMediumContrast = Color(0xFFFFFFFF)
val secondaryLightMediumContrast = Color(0xFF004E1C)
val onSecondaryLightMediumContrast = Color(0xFFFFFFFF)
val secondaryContainerLightMediumContrast = Color(0xFF008736)
val onSecondaryContainerLightMediumContrast = Color(0xFFFFFFFF)
val tertiaryLightMediumContrast = Color(0xFF633900)
val onTertiaryLightMediumContrast = Color(0xFFFFFFFF)
val tertiaryContainerLightMediumContrast = Color(0xFFA16823)
val onTertiaryContainerLightMediumContrast = Color(0xFFFFFFFF)
val errorLightMediumContrast = Color(0xFF8B0014)
val onErrorLightMediumContrast = Color(0xFFFFFFFF)
val errorContainerLightMediumContrast = Color(0xFFD7383C)
val onErrorContainerLightMediumContrast = Color(0xFFFFFFFF)
val backgroundLightMediumContrast = Color(0xFFFCF8FA)
val onBackgroundLightMediumContrast = Color(0xFF1C1B1D)
val surfaceLightMediumContrast = Color(0xFFFCF8FA)
val onSurfaceLightMediumContrast = Color(0xFF1C1B1D)
val surfaceVariantLightMediumContrast = Color(0xFFE3E1E9)
val onSurfaceVariantLightMediumContrast = Color(0xFF424248)
val outlineLightMediumContrast = Color(0xFF5F5E65)
val outlineVariantLightMediumContrast = Color(0xFF7B7A81)
val scrimLightMediumContrast = Color(0xFF000000)
val inverseSurfaceLightMediumContrast = Color(0xFF313032)
val inverseOnSurfaceLightMediumContrast = Color(0xFFF3F0F1)
val inversePrimaryLightMediumContrast = Color(0xFFC3C5DC)
val surfaceDimLightMediumContrast = Color(0xFFDCD9DB)
val surfaceBrightLightMediumContrast = Color(0xFFFCF8FA)
val surfaceContainerLowestLightMediumContrast = Color(0xFFFFFFFF)
val surfaceContainerLowLightMediumContrast = Color(0xFFF6F3F4)
val surfaceContainerLightMediumContrast = Color(0xFFF0EDEE)
val surfaceContainerHighLightMediumContrast = Color(0xFFEBE7E9)
val surfaceContainerHighestLightMediumContrast = Color(0xFFE5E1E3)

val primaryLightHighContrast = Color(0xFF1E2132)
val onPrimaryLightHighContrast = Color(0xFFFFFFFF)
val primaryContainerLightHighContrast = Color(0xFF3F4255)
val onPrimaryContainerLightHighContrast = Color(0xFFFFFFFF)
val secondaryLightHighContrast = Color(0xFF00290B)
val onSecondaryLightHighContrast = Color(0xFFFFFFFF)
val secondaryContainerLightHighContrast = Color(0xFF004E1C)
val onSecondaryContainerLightHighContrast = Color(0xFFFFFFFF)
val tertiaryLightHighContrast = Color(0xFF351C00)
val onTertiaryLightHighContrast = Color(0xFFFFFFFF)
val tertiaryContainerLightHighContrast = Color(0xFF633900)
val onTertiaryContainerLightHighContrast = Color(0xFFFFFFFF)
val errorLightHighContrast = Color(0xFF4D0007)
val onErrorLightHighContrast = Color(0xFFFFFFFF)
val errorContainerLightHighContrast = Color(0xFF8B0014)
val onErrorContainerLightHighContrast = Color(0xFFFFFFFF)
val backgroundLightHighContrast = Color(0xFFFCF8FA)
val onBackgroundLightHighContrast = Color(0xFF1C1B1D)
val surfaceLightHighContrast = Color(0xFFFCF8FA)
val onSurfaceLightHighContrast = Color(0xFF000000)
val surfaceVariantLightHighContrast = Color(0xFFE3E1E9)
val onSurfaceVariantLightHighContrast = Color(0xFF232329)
val outlineLightHighContrast = Color(0xFF424248)
val outlineVariantLightHighContrast = Color(0xFF424248)
val scrimLightHighContrast = Color(0xFF000000)
val inverseSurfaceLightHighContrast = Color(0xFF313032)
val inverseOnSurfaceLightHighContrast = Color(0xFFFFFFFF)
val inversePrimaryLightHighContrast = Color(0xFFEAEAFF)
val surfaceDimLightHighContrast = Color(0xFFDCD9DB)
val surfaceBrightLightHighContrast = Color(0xFFFCF8FA)
val surfaceContainerLowestLightHighContrast = Color(0xFFFFFFFF)
val surfaceContainerLowLightHighContrast = Color(0xFFF6F3F4)
val surfaceContainerLightHighContrast = Color(0xFFF0EDEE)
val surfaceContainerHighLightHighContrast = Color(0xFFEBE7E9)
val surfaceContainerHighestLightHighContrast = Color(0xFFE5E1E3)

val primaryDark = Color(0xFFC3C5DC)
val onPrimaryDark = Color(0xFF2C2F41)
val primaryContainerDark = Color(0xFF3B3E50)
val onPrimaryContainerDark = Color(0xFFD0D2E9)
val secondaryDark = Color(0xFFFFFFFF)
val onSecondaryDark = Color(0xFF003912)
val secondaryContainerDark = Color(0xFF46F274)
val onSecondaryContainerDark = Color(0xFF004A1A)
val tertiaryDark = Color(0xFFFFE8D5)
val onTertiaryDark = Color(0xFF492900)
val tertiaryContainerDark = Color(0xFFFAB468)
val onTertiaryContainerDark = Color(0xFF4D2B00)
val errorDark = Color(0xFFFFB3AE)
val onErrorDark = Color(0xFF68000C)
val errorContainerDark = Color(0xFFD7383C)
val onErrorContainerDark = Color(0xFFFFFFFF)
val backgroundDark = Color(0xFF131315)
val onBackgroundDark = Color(0xFFE5E1E3)
val surfaceDark = Color(0xFF131315)
val onSurfaceDark = Color(0xFFE5E1E3)
val surfaceVariantDark = Color(0xFF46464C)
val onSurfaceVariantDark = Color(0xFFC7C5CD)
val outlineDark = Color(0xFF919097)
val outlineVariantDark = Color(0xFF46464C)
val scrimDark = Color(0xFF000000)
val inverseSurfaceDark = Color(0xFFE5E1E3)
val inverseOnSurfaceDark = Color(0xFF313032)
val inversePrimaryDark = Color(0xFF5A5D71)
val surfaceDimDark = Color(0xFF131315)
val surfaceBrightDark = Color(0xFF3A393A)
val surfaceContainerLowestDark = Color(0xFF0E0E0F)
val surfaceContainerLowDark = Color(0xFF1C1B1D)
val surfaceContainerDark = Color(0xFF201F21)
val surfaceContainerHighDark = Color(0xFF2A2A2B)
val surfaceContainerHighestDark = Color(0xFF353436)

val primaryDarkMediumContrast = Color(0xFFC7C9E0)
val onPrimaryDarkMediumContrast = Color(0xFF121526)
val primaryContainerDarkMediumContrast = Color(0xFF8D8FA5)
val onPrimaryContainerDarkMediumContrast = Color(0xFF000000)
val secondaryDarkMediumContrast = Color(0xFFFFFFFF)
val onSecondaryDarkMediumContrast = Color(0xFF003912)
val secondaryContainerDarkMediumContrast = Color(0xFF46F274)
val onSecondaryContainerDarkMediumContrast = Color(0xFF00260A)
val tertiaryDarkMediumContrast = Color(0xFFFFE8D5)
val onTertiaryDarkMediumContrast = Color(0xFF492900)
val tertiaryContainerDarkMediumContrast = Color(0xFFFAB468)
val onTertiaryContainerDarkMediumContrast = Color(0xFF130700)
val errorDarkMediumContrast = Color(0xFFFFB9B5)
val onErrorDarkMediumContrast = Color(0xFF370003)
val errorContainerDarkMediumContrast = Color(0xFFFE5454)
val onErrorContainerDarkMediumContrast = Color(0xFF000000)
val backgroundDarkMediumContrast = Color(0xFF131315)
val onBackgroundDarkMediumContrast = Color(0xFFE5E1E3)
val surfaceDarkMediumContrast = Color(0xFF131315)
val onSurfaceDarkMediumContrast = Color(0xFFFDFAFB)
val surfaceVariantDarkMediumContrast = Color(0xFF46464C)
val onSurfaceVariantDarkMediumContrast = Color(0xFFCBCAD1)
val outlineDarkMediumContrast = Color(0xFFA3A2A9)
val outlineVariantDarkMediumContrast = Color(0xFF838289)
val scrimDarkMediumContrast = Color(0xFF000000)
val inverseSurfaceDarkMediumContrast = Color(0xFFE5E1E3)
val inverseOnSurfaceDarkMediumContrast = Color(0xFF2A2A2B)
val inversePrimaryDarkMediumContrast = Color(0xFF44475A)
val surfaceDimDarkMediumContrast = Color(0xFF131315)
val surfaceBrightDarkMediumContrast = Color(0xFF3A393A)
val surfaceContainerLowestDarkMediumContrast = Color(0xFF0E0E0F)
val surfaceContainerLowDarkMediumContrast = Color(0xFF1C1B1D)
val surfaceContainerDarkMediumContrast = Color(0xFF201F21)
val surfaceContainerHighDarkMediumContrast = Color(0xFF2A2A2B)
val surfaceContainerHighestDarkMediumContrast = Color(0xFF353436)

val primaryDarkHighContrast = Color(0xFFFCFAFF)
val onPrimaryDarkHighContrast = Color(0xFF000000)
val primaryContainerDarkHighContrast = Color(0xFFC7C9E0)
val onPrimaryContainerDarkHighContrast = Color(0xFF000000)
val secondaryDarkHighContrast = Color(0xFFFFFFFF)
val onSecondaryDarkHighContrast = Color(0xFF000000)
val secondaryContainerDarkHighContrast = Color(0xFF46F274)
val onSecondaryContainerDarkHighContrast = Color(0xFF000000)
val tertiaryDarkHighContrast = Color(0xFFFFFAF8)
val onTertiaryDarkHighContrast = Color(0xFF000000)
val tertiaryContainerDarkHighContrast = Color(0xFFFFBE7A)
val onTertiaryContainerDarkHighContrast = Color(0xFF000000)
val errorDarkHighContrast = Color(0xFFFFF9F9)
val onErrorDarkHighContrast = Color(0xFF000000)
val errorContainerDarkHighContrast = Color(0xFFFFB9B5)
val onErrorContainerDarkHighContrast = Color(0xFF000000)
val backgroundDarkHighContrast = Color(0xFF131315)
val onBackgroundDarkHighContrast = Color(0xFFE5E1E3)
val surfaceDarkHighContrast = Color(0xFF131315)
val onSurfaceDarkHighContrast = Color(0xFFFFFFFF)
val surfaceVariantDarkHighContrast = Color(0xFF46464C)
val onSurfaceVariantDarkHighContrast = Color(0xFFFCFAFF)
val outlineDarkHighContrast = Color(0xFFCBCAD1)
val outlineVariantDarkHighContrast = Color(0xFFCBCAD1)
val scrimDarkHighContrast = Color(0xFF000000)
val inverseSurfaceDarkHighContrast = Color(0xFFE5E1E3)
val inverseOnSurfaceDarkHighContrast = Color(0xFF000000)
val inversePrimaryDarkHighContrast = Color(0xFF26293B)
val surfaceDimDarkHighContrast = Color(0xFF131315)
val surfaceBrightDarkHighContrast = Color(0xFF3A393A)
val surfaceContainerLowestDarkHighContrast = Color(0xFF0E0E0F)
val surfaceContainerLowDarkHighContrast = Color(0xFF1C1B1D)
val surfaceContainerDarkHighContrast = Color(0xFF201F21)
val surfaceContainerHighDarkHighContrast = Color(0xFF2A2A2B)
val surfaceContainerHighestDarkHighContrast = Color(0xFF353436)

val customColor1Light = Color(0xFF006877)
val onCustomColor1Light = Color(0xFFFFFFFF)
val customColor1ContainerLight = Color(0xFF9BEDFF)
val onCustomColor1ContainerLight = Color(0xFF004E5A)
val customColor2Light = Color(0xFFA42E79)
val onCustomColor2Light = Color(0xFFFFFFFF)
val customColor2ContainerLight = Color(0xFFFF8CCB)
val onCustomColor2ContainerLight = Color(0xFF500037)

val customColor1LightMediumContrast = Color(0xFF004A55)
val onCustomColor1LightMediumContrast = Color(0xFFFFFFFF)
val customColor1ContainerLightMediumContrast = Color(0xFF008092)
val onCustomColor1ContainerLightMediumContrast = Color(0xFFFFFFFF)
val customColor2LightMediumContrast = Color(0xFF81085B)
val onCustomColor2LightMediumContrast = Color(0xFFFFFFFF)
val customColor2ContainerLightMediumContrast = Color(0xFFC04690)
val onCustomColor2ContainerLightMediumContrast = Color(0xFFFFFFFF)

val customColor1LightHighContrast = Color(0xFF00272D)
val onCustomColor1LightHighContrast = Color(0xFFFFFFFF)
val customColor1ContainerLightHighContrast = Color(0xFF004A55)
val onCustomColor1ContainerLightHighContrast = Color(0xFFFFFFFF)
val customColor2LightHighContrast = Color(0xFF480031)
val onCustomColor2LightHighContrast = Color(0xFFFFFFFF)
val customColor2ContainerLightHighContrast = Color(0xFF81085B)
val onCustomColor2ContainerLightHighContrast = Color(0xFFFFFFFF)

val customColor1Dark = Color(0xFFFFFFFF)
val onCustomColor1Dark = Color(0xFF00363E)
val customColor1ContainerDark = Color(0xFF84E2F6)
val onCustomColor1ContainerDark = Color(0xFF004651)
val customColor2Dark = Color(0xFFFFB5DA)
val onCustomColor2Dark = Color(0xFF620044)
val customColor2ContainerDark = Color(0xFFF873C0)
val onCustomColor2ContainerDark = Color(0xFF330022)

val customColor1DarkMediumContrast = Color(0xFFFFFFFF)
val onCustomColor1DarkMediumContrast = Color(0xFF00363E)
val customColor1ContainerDarkMediumContrast = Color(0xFF84E2F6)
val onCustomColor1ContainerDarkMediumContrast = Color(0xFF00232A)
val customColor2DarkMediumContrast = Color(0xFFFFB5DA)
val onCustomColor2DarkMediumContrast = Color(0xFF330022)
val customColor2ContainerDarkMediumContrast = Color(0xFFF873C0)
val onCustomColor2ContainerDarkMediumContrast = Color(0xFF000000)

val customColor1DarkHighContrast = Color(0xFFFFFFFF)
val onCustomColor1DarkHighContrast = Color(0xFF000000)
val customColor1ContainerDarkHighContrast = Color(0xFF84E2F6)
val onCustomColor1ContainerDarkHighContrast = Color(0xFF000000)
val customColor2DarkHighContrast = Color(0xFFFFF9F9)
val onCustomColor2DarkHighContrast = Color(0xFF000000)
val customColor2ContainerDarkHighContrast = Color(0xFFFFB5DA)
val onCustomColor2ContainerDarkHighContrast = Color(0xFF000000)
