package me.nielps.da.yafte.domain

import me.nielps.da.yafte.data.command.CommandRepository
import me.tatarka.inject.annotations.Inject

@Inject
class CompleteCommandUseCase(
    private val commandRepository: CommandRepository,
) {
    operator fun invoke(command: String): String = commandRepository.complete(command)
}