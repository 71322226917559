package me.nielps.da.yafte.terminal

import androidx.compose.ui.text.TextRange
import androidx.compose.ui.text.input.TextFieldValue
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.collections.immutable.plus
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import me.nielps.da.yafte.domain.CompleteCommandUseCase
import me.nielps.da.yafte.domain.GetWorkingDirectoryUseCase
import me.nielps.da.yafte.domain.RunCommandUseCase
import me.tatarka.inject.annotations.Inject

@Inject
class TerminalViewModel(
    private val runCommandUseCase: RunCommandUseCase,
    private val completeCommandUseCase: CompleteCommandUseCase,
    private val getWorkingDirectoryUseCase: GetWorkingDirectoryUseCase,
) : ViewModel() {
    private val _uiState = MutableStateFlow(TerminalState())
    val uiState: StateFlow<TerminalState> = _uiState.asStateFlow()

    init {
        observeWorkingDirectory()
    }

    private fun observeWorkingDirectory() {
        getWorkingDirectoryUseCase()
            .onEach { cwd ->
                _uiState.update {
                    it.copy(prompt = "daniel@web:$cwd$ ")
                }
            }
            .launchIn(viewModelScope)
    }

    fun onValueChange(newValue: TextFieldValue) {
        _uiState.update { it.copy(input = newValue) }
    }

    fun onSend(command: String) = viewModelScope.launch {
        val output = runCommandUseCase(command)
        _uiState.update {
            val lines = "${it.prompt}${command}" + (output?.let { "\n$output" } ?: "")
            it.copy(
                history = it.history + lines,
                input = TextFieldValue("", selection = TextRange.Zero)
            )
        }
    }

    fun onAutoComplete(command: String) = viewModelScope.launch {
        val newValue = completeCommandUseCase(command)
        _uiState.update {
            it.copy(
                input = TextFieldValue(newValue, selection = TextRange(newValue.length))
            )
        }
    }
}
