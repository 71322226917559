package me.nielps.da.yafte.data.command

import me.tatarka.inject.annotations.Inject

@Inject
class HelpCommandRunner : CommandRunner {
    override suspend fun run(args: List<String>): String = """
        Available commands:
        
        cd [dir]    Changes to directory [dir], or ~ if not specified
        ls [dir]    Lists the content of directory [dir], or the current directory if not specified
        cat [file]  Prints the contents of file [file]
        help        Prints this help
    """.trimIndent()
}