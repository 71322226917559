package me.nielps.da.yafte.terminal

import androidx.compose.ui.text.input.TextFieldValue
import kotlinx.collections.immutable.PersistentList
import kotlinx.collections.immutable.persistentListOf

data class TerminalState(
    val prompt: String = "daniel@web:~$ ",
    val input: TextFieldValue = TextFieldValue(""),
    val history: PersistentList<String> = persistentListOf(
        "Welcome to my portfolio!\n\nFor help and available commands, try `help`."
    ),
)
