package me.nielps.da.yafte.di

import me.nielps.da.yafte.data.command.di.CommandComponent
import me.nielps.da.yafte.terminal.TerminalViewModel
import me.tatarka.inject.annotations.Component

@ApplicationScope
@Component
abstract class ApplicationComponent : CommandComponent {
    abstract val terminalViewModel: TerminalViewModel

    companion object
}

expect fun ApplicationComponent.Companion.create(): ApplicationComponent