package me.nielps.da.yafte.data.command

import me.tatarka.inject.annotations.Inject

@Inject
class CommandRepository(
    private val commands: Map<String, CommandRunner>
) {
    suspend fun run(command: String, args: List<String>): CommandResult {
        val runner = commands.getOrElse(command) { return CommandResult.Error("$command: command not found...") }
        val output = try {
            runner.run(args)
        } catch (e: CommandException) {
            return CommandResult.Error(e.message)
        }

        return CommandResult.Success(output)
    }

    fun complete(input: String): String = runCatching {
        commands.keys
            .single { it.startsWith(input) }
    }.getOrElse { input }
}

sealed interface CommandResult {
    data class Success(val output: String? = null): CommandResult
    data class Error(val message: String? = null): CommandResult
}